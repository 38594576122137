define("discourse/plugins/poll/discourse/initializers/add-poll-ui-builder", ["exports", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "@ember/application"], function (_exports, _decorators, _pluginApi, _pollUiBuilder, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePollUIBuilder(api) {
    api.modifyClass("controller:composer", dt7948.p({
      pluginId: "discourse-poll-ui-builder",
      canBuildPoll(pollEnabled, minimumTrustLevel, pmWithNonHumanUser) {
        return pollEnabled && (pmWithNonHumanUser || this.currentUser && (this.currentUser.staff || this.currentUser.trust_level >= minimumTrustLevel));
      },
      actions: {
        showPollBuilder() {
          (0, _application.getOwner)(this).lookup("service:modal").show(_pollUiBuilder.default, {
            model: {
              toolbarEvent: this.toolbarEvent
            }
          });
        }
      }
    }, [["method", "canBuildPoll", [(0, _decorators.default)("siteSettings.poll_enabled", "siteSettings.poll_minimum_trust_level_to_create", "model.topic.pm_with_non_human_user")]]]));
    api.addToolbarPopupMenuOptionsCallback(() => {
      return {
        action: "showPollBuilder",
        icon: "chart-bar",
        label: "poll.ui_builder.title",
        condition: "canBuildPoll"
      };
    });
  }
  var _default = _exports.default = {
    name: "add-poll-ui-builder",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initializePollUIBuilder);
    }
  };
});